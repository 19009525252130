import type { HeroMultiLinkProps } from '~/components/showcase/HeroMultiLink.vue'

export function useHeroMultiLink(
  type: MaybeRefOrGetter<'offers_artists' | 'regions_locations'>,
): HeroMultiLinkProps {
  const links = computed(() =>
    toValue(type) === 'offers_artists'
      ? [
          // offer is default route
          { title: 'Weitere Angebote entdecken', to: '/' },
          { title: 'Weitere Künstler:innen entdecken', to: '/kuenstler-innen' },
        ]
      : [
          { title: 'Weitere Regionen entdecken', to: '/regionen' },
          { title: 'Weitere Kunsträume entdecken', to: '/kunstszene' },
        ],
  )
  return reactive({ links })
}
